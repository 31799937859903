<template>
  <div>
    <b-modal
      id="modal-project"
      ref="modal-project"
      size="xl"
      :title="getTitle()"
      :ok-disabled="$v.$invalid || isLoadingModal"
      :ok-title="modalName === 'add' ? $t('table.add') : $t('table.save')"
      :cancel-title="$t('table.cancel')"
      :hide-footer="!canEdit"
      @ok.prevent="showModalProjectConfirm"
    >
      <SpinnerLoader v-if="isLoadingModal" :loading="isLoadingModal === true ? 'loading' : 'success'" />
      <form v-else @submit.stop.prevent="checkIfValidAddEditThenEnter">
        <input type="submit" value="Submit" class="hidden-submit" />
        <div class="d-flex flex-wrap align-items-center gap-3">
          <div class="d-flex project-input-group">
            <b-form-group style="width: 90%" class="mr-2 mb-0" :label="$t('table.projectName')" label-for="input-1-pr-name">
              <b-form-input
                id="input-1-pr-name"
                v-model="selects.projectName"
                type="text"
                :placeholder="$t('table.projectName')"
                required
                autofocus
                :disabled="(modalName === 'edit' && selects.close) || !canEdit"
              ></b-form-input>
            </b-form-group>
            <b-form-checkbox
              id="checkbox-close"
              v-model="selects.close"
              name="checkbox-close"
              class="custom-control-inline ml-1"
              style="margin-top: 2.4rem"
              :disabled="!canEdit"
            >
              {{ $t('table.close') }}
            </b-form-checkbox>
          </div>
          <b-form-group class="project-input-group mb-0" :label="$t('table.selectChannels')">
            <MultiSelect
              v-model="selects.channels"
              :options="modalChannelsList"
              :multiple="true"
              :close-on-select="false"
              :placeholder="$t('table.selectChannels')"
              label="name"
              track-by="id"
              :disabled="(modalName === 'edit' && selects.close) || !canEdit"
              data-automatic="ms-channels"
              @input="buildBudgetTable"
            >
              <template v-if="hasOrders" slot="tag" slot-scope="{ option }">
                <span class="multiselect__tag" style="padding: 4px 10px">{{ option.name }}</span>
              </template>
            </MultiSelect>
          </b-form-group>

          <b-form-group class="project-input-group mb-0" :label="$t('table.selectAgency')">
            <MultiSelect
              v-model="selects.agency"
              :options="agenciesList"
              :placeholder="$t('table.selectAgency')"
              label="name"
              track-by="id"
              :disabled="hasOrders || (modalName === 'edit' && selects.close) || !canEdit"
              data-automatic="ms-agency"
            ></MultiSelect>
          </b-form-group>
          <b-form-group class="project-input-group mb-0" :label="$t('table.selectAdvertiser')">
            <MultiSelect
              v-model="selects.advertiser"
              :options="advertisersList"
              :placeholder="$t('table.selectAdvertiser')"
              label="name"
              track-by="id"
              :disabled="hasOrders || (modalName === 'edit' && selects.close) || !canEdit || !selects.agency"
              data-automatic="ms-advertiser"
            ></MultiSelect>
          </b-form-group>

          <b-form-group class="project-input-group mb-0" :label="$t('table.selectType')">
            <MultiSelect
              v-model="selects.type"
              :options="placementTypeWithoutAll"
              :placeholder="$t('table.selectType')"
              label="name"
              track-by="id"
              :disabled="hasOrders || (modalName === 'edit' && selects.close) || !canEdit"
              data-automatic="ms-placement-type"
            ></MultiSelect>
          </b-form-group>
          <b-form-group class="project-input-group mb-0" :label="$t('table.selectCommercialType')">
            <MultiSelect
              v-model="selects.commercial"
              :options="commercialTypeWithoutAll"
              :placeholder="$t('table.selectCommercialType')"
              label="name"
              track-by="id"
              :disabled="hasOrders || (modalName === 'edit' && selects.close) || !canEdit"
              data-automatic="ms-commercial-type"
            ></MultiSelect>
          </b-form-group>

          <b-form-group class="project-input-group mb-0" :label="$t('table.writeDateFrom')" label-for="datepicker-buttons-from">
            <datepicker-wrapper
              id="datepicker-buttons-from"
              v-model="selects.start"
              required
              :disabled="hasOrders || (modalName === 'edit' && selects.close) || !canEdit"
              @input="loadPriceProjectsList"
            />
          </b-form-group>
          <b-form-group class="project-input-group mb-0" :label="$t('table.writeDateTo')" label-for="datepicker-buttons-to">
            <datepicker-wrapper
              id="datepicker-buttons-to"
              v-model="selects.end"
              required
              :min="getMaxDate(selects.endFromEdit, selects.start)"
              :max="yearLimit"
              :disabled="(modalName === 'edit' && selects.close) || !canEdit || !selects.start"
            />
          </b-form-group>
        </div>

        <div class="d-flex align-items-center my-3">
          <currency-input
            v-model="selects.budget"
            class="project-input-group mr-4"
            :placeholder="$t('table.budget')"
            data-automatic="input-budget"
            :disabled="(modalName === 'edit' && selects.close) || !canEdit"
          />
          <b-form-checkbox
            id="checkbox-taxes"
            v-model="selects.taxes"
            name="checkbox-taxes"
            class="custom-control-inline"
            :disabled="!canEdit || (modalName === 'edit' && selects.close)"
          >
            {{ $t('table.taxes') }}
          </b-form-checkbox>
          <b-form-checkbox
            id="checkbox-limit-budgets"
            v-model="selects.isLimitedBudgets"
            name="checkbox-limit-budgets"
            class="custom-control-inline"
            :disabled="!canEdit || (modalName === 'edit' && selects.close)"
          >
            {{ $t('table.toLimitBudgets') }}
          </b-form-checkbox>
          <b-button
            v-if="canViewDiscounts"
            :disabled="!(selects.channels && selects.channels.length > 0 && selects.start && selects.end)"
            :title="!(selects.channels && selects.channels.length > 0 && selects.start && selects.end) ? $t('table.selectChannelsAndDatesFirst') : ''"
            data-automatic="discounts-btn"
            @click="isOpenModalDiscounts = true"
          >
            {{ $t('table.discounts') }}
          </b-button>
          <b-button
            v-b-modal.project-channels-budgets
            class="ml-3"
            :disabled="!(selects.channels && selects.channels.length > 0 && selects.start && selects.end)"
            :title="!(selects.channels && selects.channels.length > 0 && selects.start && selects.end) ? $t('table.selectChannelsAndDatesFirst') : ''"
            data-automatic="channels-budgets-btn"
          >
            {{ $t('table.budgets') }}
          </b-button>
        </div>
        <div class="d-flex align-items-center my-3">
          <b-form-checkbox
            id="checkbox-allow-ama-set-comm"
            v-model="selects.allowAMAEditMPC"
            name="checkbox-allow-ama-set-comm"
            class="custom-control-inline"
            :disabled="!canEdit || (modalName === 'edit' && selects.close)"
          >
            {{ $t('table.allowAMAEditMPC') }}
          </b-form-checkbox>
        </div>
        <template v-if="selects.channels && selects.channels.length > 0">
          <hr />
          <div class="d-flex justify-content-between align-items-center">
            <h6 class="d-inline-block mb-0">{{ $t('table.budgets') }}</h6>
          </div>
          <div v-if="selects.budgetSettings.length > 0" class="table-responsive">
            <table id="budgets-table" class="table table-sm mt-2 ws-nowrap w-auto">
              <thead>
                <tr>
                  <th class="pl-0">{{ $t('channelModal.channel') }}</th>
                  <th>{{ $t('channelModal.measurements') }}</th>
                  <th>{{ $t('channelModal.target') }}</th>
                  <th colspan="2">{{ $t('table.priceProject') }}</th>
                </tr>
              </thead>
              <tbody class="vertical-align-middle">
                <tr v-for="(sel, index) in selects.budgetSettings" :key="index">
                  <td class="pl-0">
                    <select
                      v-model="sel.channel"
                      class="custom-select custom-select-sm"
                      style="width: 130px"
                      data-automatic="select-bs-channel"
                      disabled
                      @change="setMeasurementAndTarget(sel.channel, index)"
                    >
                      <option disabled value=""></option>
                      <option v-for="(ch, i) in selects.channels" :key="i" :value="ch">{{ ch.name }}</option>
                    </select>
                  </td>
                  <td>
                    {{ sel.measurement }}
                  </td>
                  <td>
                    {{ sel.target }}
                  </td>
                  <td>
                    <select
                      v-if="!(sel.priceProjectData && sel.priceProjectData.id)"
                      v-model="sel.priceProject"
                      class="custom-select custom-select-sm"
                      style="width: 300px"
                      data-automatic="select-bs-price-project"
                      :disabled="(modalName === 'edit' && selects.close) || !canEdit || !selects.start"
                      required
                      @change="loadPriceProjectData(sel)"
                    >
                      <option disabled value=""></option>
                      <option v-for="(pr, i) in sel.priceProjectList" :key="i" :value="pr">
                        [{{ pr.type === 'time_slots' ? 'Time Slots' : 'Fix GRP' }}] {{ pr.name }}
                      </option>
                    </select>
                    <template v-else>{{ sel.priceProjectData && sel.priceProjectData.name ? sel.priceProjectData.name : '' }}</template>
                  </td>
                  <td>
                    <button
                      class="btn btn-sm btn-outline-danger"
                      type="button"
                      :disabled="!sel.priceProjectData || isLoadingPriceProjectInfo"
                      @click="showModalPriceProjects(sel.priceProjectData, index)"
                    >
                      {{ modalName === 'edit' && selects.close ? $t('table.openVerb') : $t('table.edit') }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </form>
    </b-modal>

    <!-- CONFIRM -->
    <b-modal
      ref="modal-project-confirm"
      size="sm"
      :title="modalName === 'add' ? $t('table.addProject') : $t('table.editProject')"
      :ok-title="$t('table.yes')"
      :cancel-title="$t('table.no')"
      auto-focus-button="ok"
      :busy="isModalBusy"
      @ok.prevent="Project"
    >
      {{ modalName === 'add' ? $t('table.addProject') : $t('table.editProject') }} {{ selects.projectName }}?
    </b-modal>

    <!-- DELETE PROJECT MODAL -->
    <b-modal
      ref="delete-project-confirm"
      size="sm"
      :title="$t('table.deleteProject')"
      :ok-title="$t('table.yes')"
      :cancel-title="$t('table.no')"
      auto-focus-button="ok"
      :busy="isModalBusy"
      @ok.prevent="deleteProjects"
    >
      <p class="my-2">
        {{ $t('table.deleteProject') }}
        <span class="text-danger">{{ modalCurrentProject ? modalCurrentProject.name : '' }}</span>
        ?
      </p>
    </b-modal>

    <ModalDiscounts
      modal-name="project"
      :discounts-edit-list="discountsEditList"
      :premium-discounts-edit-list="premiumDiscountsEditList"
      :modal-discounts-type="modalName"
      :is-open-modal="isOpenModalDiscounts"
      :can-edit="modalName === 'edit' && selects.close ? false : canEditDiscounts"
      :can-delete="modalName === 'edit' && selects.close ? false : canDeleteDiscounts"
      :can-add="modalName === 'edit' && selects.close ? false : canAddDiscounts"
      :channels_id_list="selects.channels.length > 0 ? selects.channels.map((el) => el.id) : undefined"
      :date_from="selects.start ? selects.start : undefined"
      :date_to="selects.end ? selects.end : undefined"
      @isOpenModal="isOpenModalDiscounts = false"
      @transferDiscounts="transferDiscounts"
      @refreshData="refreshData"
      @removeDiscount="removeDiscount"
    />

    <ModalChannelsBudgets
      v-model="selects.budgetSettings"
      :can-edit="modalName === 'edit' && selects.close ? false : canEdit"
      :is-limited-budgets="selects.isLimitedBudgets"
      :is-closed="modalName === 'edit' && selects.close"
    />
    <ModalPriceProjects
      :current-price-project="currentPriceProject"
      :project-row-index="projectRowIndex"
      :from-project="true"
      modal-type="edit"
      :project-name="selects.projectName"
      :can-edit="modalName === 'edit' && selects.close ? false : true"
      @updatePriceProjectInRow="updatePriceProjectInRow"
    />
  </div>
</template>

<script>
import MultiSelect from '@/components/MultiSelect';
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import errorsHandler from '@/utils/errorsHandler';
import { mapGetters } from 'vuex';
import SpinnerLoader from '@/components/SpinnerLoader';
import CurrencyInput from '@/components/CurrencyInput';
import DatepickerWrapper from '@/components/DatepickerWrapper.vue';
import getMinOrMaxDate from '@/mixins/getMinOrMaxDate';
import timeConvertHelpers from '@/mixins/timeConvertHelpers';

export default {
  name: 'ModalProject',
  components: {
    MultiSelect,
    DatepickerWrapper,
    ModalDiscounts: () => import('@/components/Projects/ModalDiscounts'),
    SpinnerLoader,
    ModalChannelsBudgets: () => import('@/components/Projects/ModalChannelsBudgets'),
    CurrencyInput,
    ModalPriceProjects: () => import('@/components/PriceProjects/ModalPriceProjects'),
  },
  mixins: [getMinOrMaxDate, timeConvertHelpers],
  props: {
    isOpenModal: {
      type: String,
      default: undefined,
    },
    modalName: {
      type: String,
      default: 'add',
    },
    modalCurrentProject: {
      type: Object,
      default: undefined,
    },
    hasOrders: {
      required: true,
      type: Boolean,
    },
    canEdit: {
      type: Boolean,
    },
    modalProjectBusyChannelIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isOpenModalDiscounts: false,
      selects: {
        taxes: false,
        close: false,
        budget: '',
        projectName: '',
        start: '',
        startPrevYear: '', // to compare if changed year
        end: '',
        endFromEdit: '',
        channels: [],
        advertiser: '',
        commercial: '',
        type: '',
        discounts: undefined,
        premiumPositions: [],
        agency: '',
        budgetSettings: [],
        isLimitedBudgets: false,
        allowAMAEditMPC: false,
      },
      discountsEditList: [],
      premiumDiscountsEditList: {},
      createdProjectId: null,
      modalChannelsList: [],
      isLoadingModal: false,
      isModalBusy: false,
      currentPriceProject: {},
      isLoadingPriceProjectInfo: false,
      projectRowIndex: undefined,
    };
  },
  validations: {
    selects: {
      projectName: { required },
      start: { required },
      end: { required },
      //budget: { required },
      agency: { required },
      advertiser: { required },
      commercial: { required },
      type: { required },
      channels: { required },
      budgetSettings: {
        $each: {
          channel: { required },
          prime: { minValue: minValue(0), maxValue: maxValue(100) },
          //priceProject: {required},
          placementTypes: {
            $each: {
              type: { required },
              months: {
                $each: {
                  original_budget: { minValue: minValue(0) },
                  corrected_budget: { minValue: minValue(0) },
                },
              },
            },
          },
        },
      },
    },
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      commercialType: 'getCommercialType',
      commercialTypeWithoutAll: 'getCommercialTypeWithoutAll',
      channelsList: 'getChannelsList',
      advertisersList: 'getAdvertisersList',
      targetList: 'getTargetList',
      placementTypeWithoutAll: 'getPlacementTypeWithoutAll',
      agenciesList: 'getAgenciesList',
      modalEditChannels: 'getModalEditChannels',
      modalEditProjects: 'getModalEditProjects',
      priceProjects: 'getPriceProjects',
      modalEditPriceProjects: 'getModalEditPriceProjects',
      broadcastClosedMonths: 'getBroadcastClosedMonths',
    }),
    months() {
      return [
        this.$i18n.t('month.jan'),
        this.$i18n.t('month.feb'),
        this.$i18n.t('month.mar'),
        this.$i18n.t('month.apr'),
        this.$i18n.t('month.may'),
        this.$i18n.t('month.jun'),
        this.$i18n.t('month.jul'),
        this.$i18n.t('month.aug'),
        this.$i18n.t('month.sep'),
        this.$i18n.t('month.oct'),
        this.$i18n.t('month.nov'),
        this.$i18n.t('month.dec'),
      ];
    },
    canViewDiscounts: function () {
      return this.$checkPermissions('scope.pom_discount');
    },
    canAddDiscounts: function () {
      return this.$checkPermissions('discount.pom_update');
    },
    canEditDiscounts: function () {
      return this.$checkPermissions('discount.pom_update');
    },
    canDeleteDiscounts: function () {
      return this.$checkPermissions('discount.pom_update');
    },
    canEditBudget: function () {
      return this.$checkPermissions('price.pom_update');
    },
    yearLimit() {
      return this.selects.start ? new Date(Date.UTC(+this.selects.start.slice(0, 4), 11, 31)).toISOString().slice(0, 10) : undefined;
    },
  },
  watch: {
    isOpenModal() {
      this.showModalProject();
    },
    async 'selects.agency'() {
      if (this.selects.agency && !this.isLoadingModal) {
        await this.$store.dispatch('GET_ADVERTISERS', {
          per_page: 1000,
          'filter[agency_id]': this.selects.agency ? this.selects.agency.id : null,
        });
        if (this.selects.advertiser?.agency_id !== this.selects.agency.id) this.selects.advertiser = '';
      }
    },

    async 'selects.start'() {
      if (!this.selects.start) return;
      // Load closed months for Budgets modal (disabling inputs)
      if (this.$checkPermissions('broadcasting.closed_months'))
        await this.$store.dispatch('GET_BROADCAST_CLOSED_MONTHS', { data: { year: +this.selects.start.slice(0, 4) } });
      // Reset End date if End < Start or they have different years
      if (
        this.selects.end &&
        (new Date(this.selects.end).getTime() < new Date(this.selects.start).getTime() || this.selects.start.slice(0, 4) !== this.selects.end.slice(0, 4))
      )
        this.selects.end = this.selects.start;
    },
  },
  destroyed() {
    this.$store.commit('clearBroadcastClosedMonths');
  },
  methods: {
    getTitle() {
      if (this.modalName === 'add') return this.$i18n.t('table.addProject');
      else if (this.modalName === 'edit' && !this.canEdit) return this.selects.projectName;
      else return this.$i18n.t('table.editProject');
    },
    async showModalProject() {
      if (this.isOpenModal) {
        if (this.modalName !== 'delete') {
          this.isLoadingModal = true;
          this.$refs['modal-project'].show();
          this.clearFields();
          await Promise.all([
            this.channelsList.length < 1 ? this.$store.dispatch('GET_CHANNELS', { per_page: 1000 }) : undefined,
            this.placementTypeWithoutAll.length < 1 ? this.$store.dispatch('GET_PLACEMENT_TYPE', { per_page: 1000 }) : undefined,
          ]);
          this.channelsList.forEach((el) => this.modalChannelsList.push({ name: el.name, id: el.id }));

          if (this.modalName === 'edit') {
            await this.$store.dispatch('GET_PROJECTS_ID', {
              id: this.modalCurrentProject.id,
              data: {
                include:
                  'advertiser,agency,discounts,premiumPositionDiscounts,channelProjects.channelProjectPriceProject,channelProjects.channelProjectBudgets,channelProjects.channel.channelSettings,channelProjects.channelProjectBudgetLimits',
              },
            });
            await this.$store.dispatch('GET_TARGET', { per_page: 1000, include: 'measurementCompany' });
            if (this.modalEditProjects) {
              this.selects.agency = this.modalEditProjects.agency || this.agenciesList.find((ag) => ag.id === this.modalEditProjects.agency_id);
              await this.$store.dispatch('GET_ADVERTISERS', {
                per_page: 1000,
                'filter[agency_id]': this.selects.agency?.id,
              });
              this.selects.advertiser =
                this.modalEditProjects.advertiser || this.advertisersList.find((advertiser) => advertiser.id === this.modalEditProjects.advertiser_id);
              this.selects.projectName = this.modalEditProjects.name;
              this.selects.taxes = this.modalEditProjects.taxes;
              this.selects.close = this.modalEditProjects.is_closed;
              this.selects.allowAMAEditMPC = this.modalEditProjects.are_mpc_settings_editable_by_agencies;
              this.selects.isLimitedBudgets = this.modalEditProjects.budget_limits_applied;
              this.selects.budget = parseFloat(this.modalEditProjects.estimated_budget).toFixed(2);
              this.selects.start = this.modalEditProjects.date_from;
              this.selects.startPrevYear = this.selects.start?.slice(0, 4);
              this.selects.end = this.modalEditProjects.date_to;
              this.selects.endFromEdit = this.modalEditProjects.date_to;
              this.selects.commercial = this.commercialType.find((type) => type.id === this.modalEditProjects.commercial_type_id);
              this.selects.type = this.placementTypeWithoutAll.find((type) => type.id === this.modalEditProjects.placement_type_id);
              this.discountsEditList = this.modalEditProjects.discounts || [];
              this.premiumDiscountsEditList = this.modalEditProjects.premium_position_discounts;
              this.selects.premiumPositions = this.modalEditProjects.premium_position_discounts;
              const fromStart = this.selects.start?.slice(0, 4) + '-01-01';
              const fromEnd = this.selects.endFromEdit?.slice(0, 4) + '-12-31';
              await this.$store.dispatch('GET_PRICE_PROJECTS', {
                per_page: 1000,
                'filter[date_from]': fromStart,
                'filter[date_to]': fromEnd,
              });

              this.modalEditProjects?.channel_projects?.forEach((ch) => {
                if (ch.channel?.id && ch.channel?.name) {
                  this.selects.channels.push({ id: ch.channel.id, name: ch.channel.name });

                  // Disable deselecting channels if project has order(s)
                  if (this.hasOrders) {
                    const channelWithOrders = this.modalProjectBusyChannelIds.find((e) => e === ch.channel.id); // check if this channel is in any child order
                    if (channelWithOrders) {
                      const currentChannel = this.modalChannelsList.find((el) => el.id === ch.channel.id); // disable this channel in multiselect
                      if (currentChannel) currentChannel.$isDisabled = true;
                    }
                  }

                  const year = this.selects.start.slice(0, 4);
                  let targetName = '';
                  let measurementName = '';
                  if (ch.channel.channel_settings[year]?.base_target_audience_id) {
                    // Set Measurement and T.A. names in channel settings table
                    const target = this.targetList.find((t) => t.id === ch.channel.channel_settings[year].base_target_audience_id);
                    targetName = target?.name;
                    measurementName = target?.measurement_company?.name || '';
                  }

                  // For Budgets (CP) modal
                  const placementTypes = [];
                  for (let pl in ch.budgets) {
                    let percentLimit = null;
                    //find corresponding percent for pl. type in this channel data
                    if (ch.budget_limits) {
                      for (const [key, value] of Object.entries(ch.budget_limits)) {
                        if (+key === +pl) percentLimit = +value.percent;
                      }
                    }
                    //create unavailable empty months
                    const months = {};
                    for (let i = 1; i <= 12; i++) {
                      // if month data exists => push
                      if (Object.keys(ch.budgets[pl]).includes(i.toString())) {
                        months[i.toString()] = Object.values(ch.budgets[pl])[Object.keys(ch.budgets[pl]).indexOf(i.toString())];
                      } else {
                        // create empty data month (e.g. for closed months)
                        months[i.toString()] = {
                          corrected_budget: '',
                          execution: '',
                          off_prime_wgrp: '',
                          original_budget: '',
                          prime_wgrp: '',
                        };
                      }
                    }
                    placementTypes.push({
                      type: this.placementTypeWithoutAll.find((p) => p.id === +pl) || '',
                      months: months,
                      budgetLimit: percentLimit || 0,
                    });
                  }

                  // Clean-up to not brake API
                  if (ch.channel_project_price_project) {
                    if (ch.channel_project_price_project.price_project_type === 'fix_grp')
                      delete ch.channel_project_price_project.channel_project_price_project_by_time_details;
                    else if (ch.channel_project_price_project.price_project_type === 'time_slots') {
                      delete ch.channel_project_price_project.channel_project_price_project_by_grp_details;
                    }
                  }
                  const prList = this.priceProjects?.data?.filter((pr) => pr.channel_id === ch.channel.id) || [];
                  this.selects.budgetSettings.push({
                    channel: { id: ch.channel.id, name: ch.channel.name },
                    channel_projects_id: ch.id,
                    target: targetName,
                    measurement: measurementName,
                    hiddenRows: true,
                    hiddenRowsCCP: true,
                    prime: ch?.channel_project_price_project?.prime_percent ? +ch?.channel_project_price_project?.prime_percent : '',
                    priceProject: '',
                    priceProjectList: prList,
                    placementTypes: placementTypes,
                    priceProjectData: ch?.channel_project_price_project,
                  });
                }
              });
            }
          }
          this.isLoadingModal = false;
        } else {
          this.showModalDeleteProjects();
        }
        this.$emit('isOpenModal', null);
      }
    },
    hideModalProject() {
      this.$refs['modal-project'].hide();
    },
    showModalProjectConfirm() {
      this.$refs['modal-project-confirm'].show();
    },
    hideModalProjectConfirm() {
      this.$refs['modal-project-confirm'].hide();
    },

    transferDiscounts(data) {
      this.selects.discounts = data[0];

      //this.discountsEditList = this.discountsEditList.filter((el) => el.id);
      //this.discountsEditList.push(...data[0]);
      this.discountsEditList = data[0];
      this.selects.premiumPositions = data[1];
      this.premiumDiscountsEditList = data[1];
      //this.Project();
    },

    // Update project list after discount delete
    refreshData() {
      this.$emit('refreshProjects', 'restore');
    },

    clearFields() {
      this.selects.advertiser = '';
      this.selects.projectName = '';
      this.selects.taxes = false;
      this.selects.close = false;
      this.selects.isLimitedBudgets = false;
      this.selects.allowAMAEditMPC = false;
      this.selects.budget = '';
      this.selects.start = '';
      this.selects.end = '';
      this.selects.endFromEdit = '';
      this.selects.channels = [];
      this.selects.commercial = '';
      this.selects.type = '';
      this.selects.discounts = undefined;
      this.selects.premiumPositions = [];
      this.discountsEditList = [];
      this.premiumDiscountsEditList = {};
      this.selects.agency = '';
      this.selects.startPrevYear = '';
      this.modalChannelsList = [];
      this.selects.budgetSettings = [];
      this.currentPriceProject = {};
      this.projectRowIndex = undefined;
      this.$store.commit('clearPriceProjects');
    },

    buildBudgetTable() {
      this.selects.channels.forEach((el, index) => {
        // if new channel - add row
        if (!this.selects.budgetSettings.find((e) => e.channel?.id === el.id)) {
          this.selects.budgetSettings.push({
            channel: el,
            target: '',
            measurement: '',
            priceProject: '',
            priceProjectList: this.priceProjects?.data?.filter((pr) => pr.channel_id === el.id) || [],
            prime: '',
            hiddenRows: false,
            hiddenRowsCCP: false,
            placementTypes: [],
            priceProjectData: undefined,
          });
          this.setMeasurementAndTarget(el, index);
        }
      });
      // if channel was removed - delete row
      this.selects.budgetSettings.forEach((el, index) => {
        if (!this.selects.channels.find((e) => el.channel?.id === e.id)) {
          this.selects.budgetSettings.splice(index, 1);
        }
      });
    },

    // Load PP list after date selected
    async loadPriceProjectsList() {
      if (this.selects.start && this.selects.start.slice(0, 4) !== this.selects.startPrevYear) {
        this.selects.startPrevYear = this.selects.start.slice(0, 4);
        const fromStart = this.selects.start.slice(0, 4) + '-01-01';
        const fromEnd = this.selects.start.slice(0, 4) + '-12-31';
        await this.$store.dispatch('GET_PRICE_PROJECTS', {
          per_page: 1000,
          'filter[date_from]': fromStart,
          'filter[date_to]': fromEnd,
        });
        this.selects.budgetSettings.forEach((el) => {
          el.priceProjectList = this.priceProjects?.data?.filter((pr) => pr.channel_id === el.channel?.id) || [];
        });
      }

      this.selects.channels.forEach((el, index) => {
        // update TA for channels
        if (!el.target || !el.measurement) this.setMeasurementAndTarget(el, index);
      });
    },

    // Add\Edit Project
    async Project() {
      this.isModalBusy = true;
      let channelIdList = [];

      this.selects.channels.forEach((el) => {
        const budgetSettings = this.selects.budgetSettings.find((set) => el.id === set.channel?.id);
        const budgets = [];
        const budgetLimits = [];
        if (budgetSettings) {
          budgetSettings.placementTypes.forEach((pt) => {
            for (let m in pt.months) {
              budgets.push({
                placement_type_id: pt.type?.id,
                month: +m,
                original_budget: +pt.months[m].original_budget || 0,
                corrected_budget: +pt.months[m].corrected_budget || 0,
              });
            }
            budgetLimits.push({
              placement_type_id: pt.type?.id,
              percent: pt.budgetLimit,
            });
          });
          const priceProject = budgetSettings.priceProjectData ? JSON.parse(JSON.stringify(budgetSettings.priceProjectData)) : {};
          if (priceProject && priceProject.price_project_type === 'time_slots') {
            priceProject.channel_project_price_project_by_time_details.forEach((set) => {
              set.duration = set.end_interval - set.start_interval;
              delete set.time_from;
              delete set.time_to;
            });
          }
          channelIdList.push({
            channel_id: el.id,
            id: budgetSettings ? budgetSettings.channel_projects_id : undefined,
            project_id: this.modalCurrentProject?.id,
            budgets: budgetSettings ? budgets : undefined,
            budget_limits: this.selects.isLimitedBudgets ? budgetLimits : [],
            channel_project_price_project_id: priceProject?.id,
            channel_project_price_project: {
              ...priceProject,
              prime_percent: budgetSettings.prime != null && budgetSettings.prime !== '' ? +budgetSettings.prime : undefined,
            },
          });
        }
      });

      let discountsList = undefined;
      if (this.selects.discounts != null) {
        discountsList = [];
        this.selects.discounts.forEach((el, index) => {
          //if (!el.id) {
          discountsList.push({
            discount_type_id: el.discount_type_id,
            from: el.from,
            to: el.to,
            percent: el.percent,
            order: index,
          });
          //}
        });
      }

      const formData = {
        agency_id: this.selects.agency.id,
        advertiser_id: this.selects.advertiser.id,
        year_id: +this.selects.start.slice(0, 4),
        placement_type_id: this.selects.type.id,
        commercial_type_id: this.selects.commercial.id,
        name: this.selects.projectName,
        taxes: this.selects.taxes,
        is_closed: this.selects.close,
        estimated_budget: +this.selects.budget,
        date_from: this.selects.start,
        date_to: this.selects.end,
        channel_projects: channelIdList,
        discounts: this.canEditDiscounts ? discountsList : undefined,
        premium_position_discounts: this.canEditDiscounts ? this.selects.premiumPositions : undefined,
        are_mpc_settings_editable_by_agencies: this.selects.allowAMAEditMPC,
        budget_limits_applied: this.selects.isLimitedBudgets,
      };
      if (this.modalName === 'add') {
        await this.$store.dispatch('POST_PROJECTS', {
          formData,
          handler: (res) => {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('alert.addProjects'),
              text: this.selects.projectName,
            });
            this.clearFields();
            this.hideModalProject();
            this.$emit('refreshProjects', 'restore');
          },
          handlerError: (errors) => {
            errorsHandler(errors, this.$notify);
          },
        });
      } else {
        const projectsId = this.modalCurrentProject.id;
        await this.$store.dispatch('PUT_PROJECTS', {
          projectsId,
          formData,
          handler: (res) => {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('alert.editProjects'),
              text: this.selects.projectName,
            });
            this.clearFields();
            this.hideModalProject();
            this.$emit('refreshProjects', 'restore');
          },
          handlerError: (errors) => {
            errorsHandler(errors, this.$notify);
          },
        });
      }
      this.hideModalProjectConfirm();
      this.isModalBusy = false;
    },

    // modal delete
    showModalDeleteProjects() {
      this.$refs['delete-project-confirm'].show();
    },
    hideModalDeleteProjectsConfirm() {
      this.$refs['delete-project-confirm'].hide();
    },
    async deleteProjects() {
      this.isModalBusy = true;
      const formData = this.modalCurrentProject.id;
      await this.$store.dispatch('DELETE_PROJECTS_ID', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.deleteProjects'),
            text: this.modalCurrentProject.name,
          });
          this.$emit('refreshProjects');
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
          this.$emit('refreshProjects', 'restore');
        },
      });
      this.hideModalDeleteProjectsConfirm();
      this.isModalBusy = false;
    },

    checkIfValidAddEditThenEnter() {
      if (!this.$v.$invalid) this.showModalProjectConfirm();
    },

    removeDiscount(id) {
      //remove discount from list after API delete
      this.discountsEditList = this.discountsEditList.filter((el) => el.id !== id);
    },

    //Get measurement and target for channel in budget settings table
    async setMeasurementAndTarget(channel, index) {
      if (channel) {
        const year = this.selects.start?.slice(0, 4) || new Date().getFullYear();
        if (!(this.modalEditChannels && this.modalEditChannels.id === channel.id)) await this.$store.dispatch('GET_CHANNELS_ID', channel.id);

        if (this.modalEditChannels?.channel_settings[year]?.base_target_audience) {
          this.selects.budgetSettings[index].target = this.modalEditChannels.channel_settings[year].base_target_audience.name;
          this.selects.budgetSettings[index].measurement = this.modalEditChannels.channel_settings[year].base_target_audience.measurement_company?.name;
        }
      }
    },

    // Load PP details after selecting in budget settings table
    async loadPriceProjectData(item) {
      this.isLoadingPriceProjectInfo = true;
      if (item?.priceProject?.id) {
        await this.$store.dispatch('GET_PRICE_PROJECTS_ID', { id: item.priceProject.id });
        if (this.modalEditPriceProjects) {
          this.modalEditPriceProjects.price_project_id = item.priceProject.id;
          //clean-up new PP for Project API
          const PP = {
            name: this.modalEditPriceProjects.name,
            price_project_type: this.modalEditPriceProjects.type,
            price_project_id: item.priceProject.id,
            low_priority_type_discount: this.modalEditPriceProjects.low_priority_type_discount,
            auto_placement_type_discount: this.modalEditPriceProjects.auto_placement_type_discount,
            co_branding_discount: this.modalEditPriceProjects.co_branding_discount,
            is_proportional: this.modalEditPriceProjects.is_proportional,
            s5: this.modalEditPriceProjects.s5,
            s10: this.modalEditPriceProjects.s10,
            s15: this.modalEditPriceProjects.s15,
            s20: this.modalEditPriceProjects.s20,
            s25: this.modalEditPriceProjects.s25,
            s30: this.modalEditPriceProjects.s30,
            s35: this.modalEditPriceProjects.s35,
            s40: this.modalEditPriceProjects.s40,
            s45: this.modalEditPriceProjects.s45,
            s50: this.modalEditPriceProjects.s50,
            s55: this.modalEditPriceProjects.s55,
            s60: this.modalEditPriceProjects.s60,
            channel_project_price_project_by_grp_details:
              this.modalEditPriceProjects.type === 'fix_grp' ? this.modalEditPriceProjects.price_project_by_grp_details : undefined,
            channel_project_price_project_by_time_details:
              this.modalEditPriceProjects.type === 'fix_grp' ? undefined : this.modalEditPriceProjects.price_project_by_time_details,
          };

          item.priceProjectData = PP;
        }
      } else {
        item.priceProjectData = {};
      }
      this.isLoadingPriceProjectInfo = false;
    },

    async showModalPriceProjects(item, index) {
      if (item?.price_project_id || item?.id) {
        this.projectRowIndex = index;
        this.currentPriceProject = item;
        this.$bvModal.show('price-project-modal');
      }
    },

    // Update PP field for channel after submit in PP modal
    updatePriceProjectInRow(item, index) {
      if (item && index !== undefined) this.$set(this.selects.budgetSettings[index], 'priceProjectData', item);
    },
  },
};
</script>

<style lang="sass">
#modal-project > .modal-dialog
  max-width: calc(100% - 16px)
</style>

<style lang="sass" scoped>
#budgets-table,
#budgets-table .form-control-sm
  font-size: 14px !important

#budgets-table
  th,
  td
    padding-left: 12px

.m-ta-table-div
  width: 100px
  overflow: hidden
  text-overflow: ellipsis
  padding-top: 5px

.project-input-group
  width: 49%
  max-width: 600px
</style>
